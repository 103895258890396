///////////////////////////////
// Timers-related functionality
///////////////////////////////

qcodo.extend( qcodo, {
	_objTimers: {},

	clearTimeout: function(strTimerId) {
		if (qcodo._objTimers[strTimerId]) {
			clearTimeout(qcodo._objTimers[strTimerId]);
			qcodo._objTimers[strTimerId] = null;
		}
	},

	setTimeout: function(strTimerId, strAction, intDelay) {
		qcodo.clearTimeout(strTimerId);
		qcodo._objTimers[strTimerId] = setTimeout(strAction, intDelay);
	}
});

/////////////////////////////////////
// Event Object-related functionality
/////////////////////////////////////
qcodo.extend( qcodo, {
	handleEvent: function(objEvent) {
		objEvent = (objEvent) ? objEvent : ((typeof(event) == "object") ? event : null);

		if (objEvent) {
			if ( !objEvent.target )
				objEvent.target = objEvent.srcElement || document;
			if ( objEvent.target.nodeType === 3 )
				objEvent.target = objEvent.target.parentNode;
		}
		
		//qcodo.logDimensionsOverlay();
		return objEvent;
	},

	terminateEvent: function(objEvent) {
		objEvent = qcodo.handleEvent(objEvent);

		if (objEvent) {
			// Stop Propagation
			if (objEvent.preventDefault)
				objEvent.preventDefault();
			if (objEvent.stopPropagation)
				objEvent.stopPropagation();
			objEvent.cancelBubble = true;
			objEvent.returnValue = false;
		}

		return false;
	}
});