// PostBack and AjaxPostBack

qcodo.extend(qcodo, {
	updateCkeditor: function() {
		if (typeof window.CKEDITOR != 'undefined') {
			for(var i in window.CKEDITOR.instances) {
                window.CKEDITOR.instances[i].updateElement();
			}
		}
	},

	postBack: function(form, control, event, parameter) {
		var f = qcodo.objForm;
		f.Qform__FormControl.value = control;
		f.Qform__FormEvent.value = event;
		f.Qform__FormParameter.value = parameter;
		f.Qform__FormCallType.value = "Server";
		f.Qform__FormUpdates.value = qcodo.formUpdates();
		f.Qform__FormCheckableControls.value = qcodo.formCheckableControls(form, "Server");
		f.submit();
	},

	formUpdates: function() {
		qcodo.updateCkeditor();
		
		var toReturn = "";

		for (var controlId in qcodo.controlModifications) {
			if (qcodo.controlModifications.hasOwnProperty(controlId)) {
				for (var property in qcodo.controlModifications[controlId]) {
					if (qcodo.controlModifications[controlId].hasOwnProperty(property)) {
						toReturn += controlId + " " + property + " " + qcodo.controlModifications[controlId][property] + "\n";
					}
				}
			}
		}
		qcodo.controlModifications = {};

		return toReturn;
	},

	formCheckableControls: function(strForm, strCallType) {
		var form = document.getElementById(strForm);
		var toReturn = "";

		for (var index = 0; index < form.elements.length; index++) {
			if (
				(form.elements[index].type == "checkbox" || form.elements[index].type == "radio") &&
				(strCallType == "Ajax" || !form.elements[index].disabled)
			) {
				// CheckBoxList
				if (form.elements[index].id.indexOf('[') >= 0) {
					if (form.elements[index].id.indexOf('[0]') >= 0) {
						toReturn += " " + form.elements[index].id.substring(0, form.elements[index].id.length - 3);
					}
				// RadioButtonList
				} else if (form.elements[index].id.indexOf('_') >= 0) {
					if (form.elements[index].id.indexOf('_0') >= 0) {
						toReturn += " " + form.elements[index].id.substring(0, form.elements[index].id.length - 2);
					}
				// Standard Radio or Checkbox
				} else {
					toReturn += " " + form.elements[index].id;
				}
			}
		}

		return (toReturn.length > 0) ? toReturn.substring(1) : "";
	},

	ajaxQueue: [],

	postAjax: function(strForm, strControl, strEvent, strParameter, strWaitIconControlId) {
		if (!qcodo.unloadFlag) { // Only add if we're not unloaded
			if (qcodo.beforeUnloadFlag) {
                qcodo.beforeUnloadFlag = false;
            }

			var queueEmpty = false;
			if (qcodo.ajaxQueue.length == 0) { // Figure out if Queue is Empty
                queueEmpty = true;
            }

			// Enqueue the AJAX Request
            qcodo.ajaxQueue.push([strForm, strControl, strEvent, strParameter, strWaitIconControlId]);

			if (queueEmpty) { // If the Queue was originally empty, call the Dequeue
                qcodo.dequeueAjaxQueue();
            }
		}
	},
	
	clearAjaxQueue: function() {
        qcodo.ajaxQueue = [];
	},

	objAjaxWaitIcon: null,
	ajaxRequest: null,

    openErrorWindow: function(strText) {
	    var errorWindow = document.createElement('div');
	    errorWindow.setAttribute("style", "position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 999999; background-color: rgba(0, 0, 0, 0.8);");

	    var content = document.createElement('div');
	    content.setAttribute("style", "position: relative; margin: 0 auto; width: 1000px; z-index: 1000000;");

	    var iframe = document.createElement('iframe');
	    iframe.setAttribute('width', "1000px");
	    iframe.setAttribute('height', "750px");
	    iframe.setAttribute('frameborder', "0");
	    iframe.setAttribute('style', 'background-color: #ffffff; margin-top: 40px');

	    content.appendChild(iframe);
	    errorWindow.appendChild(content);
	    window.document.body.appendChild(errorWindow);

	    iframe.contentWindow.document.open();
	    iframe.contentWindow.document.write(strText);
	    iframe.contentWindow.document.close();
    },

	handleAjaxResponse: function(event) {
			if (qcodo.ajaxRequest.readyState == 4) {
			var request = qcodo.ajaxRequest;

			if (qcodo.beforeUnloadFlag == false) {
				try {
					var objXmlDoc = request.responseXML;
					if (!objXmlDoc) {
						//alert("An error occurred during AJAX Response parsing.\r\n\r\nThe error response will appear in a new popup.");
                        qcodo.openErrorWindow(request.responseText);
						return;
					} else {
						// Go through Controls
						var xmlControls = objXmlDoc.getElementsByTagName('control');

                        for (var i = 0; i < xmlControls.length; i++) {
							var controlId = xmlControls[i].attributes.getNamedItem('id').nodeValue;

							var controlHtml = "";
							if (xmlControls[i].textContent) {
								controlHtml = xmlControls[i].textContent;
							} else if (xmlControls[i].firstChild) {
		                        controlHtml = xmlControls[i].firstChild.nodeValue;
	                        }

							// Perform Callback Responsibility
							if (controlId == "Qform__FormState") {
								var formState = document.getElementById(controlId);
								formState.value = controlHtml;
							} else {
								var span = document.getElementById(controlId + "_ctl");
								if (span) {
                                    span.innerHTML = controlHtml;
                                }
							}
						}

						// Go through Commands
						var xmlCommands = objXmlDoc.getElementsByTagName('command');

						for (i = 0; i < xmlCommands.length; i++) {
							if (xmlCommands[i] && xmlCommands[i].firstChild) {
								var command = "";
								var childLength = xmlCommands[i].childNodes.length;
								for (var childIndex = 0; childIndex < childLength; childIndex++) {
									command += xmlCommands[i].childNodes[childIndex].nodeValue;
								}
								eval(command);
							}
						}
					}
				} catch (objExc) {
					alert("An error occurred during AJAX Response handling.\r\n\r\nThe error response will appear in a new popup.\r\n\r\n" + objExc.message + "\r\non line number " + objExc.lineNumber + "\r\nin file " + objExc.fileName);
                    qcodo.openErrorWindow(request.responseText);
					return;
				}
			}

			// Perform the Dequeue
            qcodo.ajaxQueue.shift();

			// Hid the Cog\Form\WaitIcon (if applicable)
			if (qcodo.objAjaxWaitIcon) {
                qcodo.objAjaxWaitIcon.style.display = 'none';
            }

			// If there are still AjaxEvents in the queue, go ahead and process/dequeue them
			if (qcodo.ajaxQueue.length > 0) {
                qcodo.dequeueAjaxQueue();
            }
		}
	},

	dequeueAjaxQueue: function() {
		if (qcodo.ajaxQueue.length > 0) {
			var form = qcodo.ajaxQueue[0][0];
            var control = qcodo.ajaxQueue[0][1];
            var event = qcodo.ajaxQueue[0][2];
            var parameter = qcodo.ajaxQueue[0][3];
            var waitIconControlId = qcodo.ajaxQueue[0][4];

			// Display WaitIcon (if applicable)
			if (waitIconControlId) {
                qcodo.objAjaxWaitIcon = qcodo.getWrapper(waitIconControlId);
				if (qcodo.objAjaxWaitIcon) {
					qcodo.objAjaxWaitIcon.style.display = 'inline';
				}
			}

			var f = qcodo.objForm;
			f.Qform__FormControl.value = control;
			f.Qform__FormEvent.value = event;
			f.Qform__FormParameter.value = parameter;
			f.Qform__FormCallType.value = "Ajax";
			f.Qform__FormUpdates.value = qcodo.formUpdates();
			f.Qform__FormCheckableControls.value = qcodo.formCheckableControls(form, "Ajax");

			var postData = "";
			for (var i = 0; i < f.elements.length; i++) {
				switch (f.elements[i].type) {
					case "checkbox":
					case "radio":
						if (f.elements[i].checked) {
							var strTestName = f.elements[i].name + "_";
							if (f.elements[i].id.substring(0, strTestName.length) == strTestName) {
								postData += "&" + f.elements[i].name + "=" + f.elements[i].id.substring(strTestName.length);
							} else {
								postData += "&" + f.elements[i].id + "=" + f.elements[i].value;
							}
						}
						break;

					case "select-multiple":
						for (var index = 0; index < f.elements[i].options.length; index++)
							if (f.elements[i].options[index].selected) {
								postData += "&" + f.elements[i].name + "=";
								postData += f.elements[i].options[index].value;
							}
						break;

					default:
						postData += "&" + f.elements[i].id + "=";

						// For Internationalization -- we must escape the element's value properly
						var postValue = f.elements[i].value;
						if (postValue) {
							postValue = postValue.replace(/\%/g, "%25");
							postValue = postValue.replace(/&/g, encodeURIComponent('&'));
							postValue = postValue.replace(/\+/g, "%2B");
						}
						postData += postValue;
						break;
				}
			}

			var uri = f.action;
            var request = new XMLHttpRequest();
			if (request) {
				request.open("POST", uri, true);
				request.setRequestHeader("Method", "POST " + uri + " HTTP/1.1");
				request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
				request.onreadystatechange = qcodo.handleAjaxResponse;

				qcodo.ajaxRequest = request;
				qcodo.ajaxRequest.send(postData);
			}
		}
	}
});

// Qcodo Shortcuts
qc.pB = qcodo.postBack;
qc.pA = qcodo.postAjax;