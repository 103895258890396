/////////////////////////////////////////////
// Control: ListBox functionality
/////////////////////////////////////////////

qcodo.lstBoxReset = function(strControlId) {
	var objListBox = document.getElementById(strControlId);
	objListBox.selectedIndex = -1;
	if (objListBox.onchange) {
        objListBox.onchange();
    }
};

qc.lstReset = qcodo.lstBoxReset;