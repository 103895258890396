///////////////////////////////////////////////////
// The Qcodo Object is used for everything in Qcodo
///////////////////////////////////////////////////

(function(qcodo) {
    qcodo.each = function(obj, fn) {
        if (obj.length == undefined) {
            for (var i in obj) {
                fn.call(obj[i], i, obj[i]);
            }
        } else {
            for (var i=0, ol=obj.length, val=obj[0]; i<ol && fn.call(val, i, val) !== false; val=obj[++i]) {}
        }
        return obj;
    };

    var createAssigner = function(keysFunc, undefinedOnly) {
        return function(obj) {
            var length = arguments.length;
            if (length < 2 || obj == null) return obj;
            for (var index = 1; index < length; index++) {
                var source = arguments[index],
                    keys = keysFunc(source),
                    l = keys.length;
                for (var i = 0; i < l; i++) {
                    var key = keys[i];
                    if (!undefinedOnly || obj[key] === void 0) obj[key] = source[key];
                }
            }
            return obj;
        };
    };

    qcodo.isObject = function(obj) {
        var type = typeof obj;
        return type === 'function' || type === 'object' && !!obj;
    };

    qcodo.allKeys = function(obj) {
        if (!qcodo.isObject(obj)) return [];
        var keys = [];
        for (var key in obj) keys.push(key);
        return keys;
    };

    qcodo.extend = createAssigner(qcodo.allKeys);

    window.qcodo = qcodo;
})({});


/////////////////////////////
// Cog\Form\Form-related functionality
/////////////////////////////

qcodo.extend(qcodo, {
    objForm: null,
    strFormId: null,

    registerForm: function(strFormId, strFormState) {
        qcodo.strFormId = strFormId;
        qcodo.objForm = document.getElementById(strFormId);

        // Register the Various Hidden Form Elements needed for Cog\Form\Form
        var formElements = [
            "Qform__FormId",
            "Qform__FormState",
            "Qform__FormControl",
            "Qform__FormEvent",
            "Qform__FormParameter",
            "Qform__FormCallType",
            "Qform__FormUpdates",
            "Qform__FormCheckableControls"
        ];
        qcodo.each(formElements, function(i, el) {
            qcodo.registerFormHiddenElement(el, document);
        });

        // Set the Cog\Form\Form's FormId and FormState
        document.getElementById("Qform__FormId").value = strFormId;
        document.getElementById("Qform__FormState").value = strFormState;
    },

    registerFormHiddenElement: function(strId, objDocument) {
        var objHiddenElement = objDocument.createElement("input");
        objHiddenElement.type = "hidden";
        objHiddenElement.id = strId;
        objHiddenElement.name = strId;
        qcodo.objForm.appendChild(objHiddenElement);
    },

    wrappers: []
});

////////////////////////////////////
// Window Unloading
////////////////////////////////////

qcodo.extend(qcodo, {
    unloadFlag: false,
    handleUnload: function() {
        qcodo.unloadFlag = true;
    },

    beforeUnloadFlag: false,
    handleBeforeUnload: function() {
        qcodo.beforeUnloadFlag = true;
    }
});

window.onunload = qcodo.handleUnload;
window.onbeforeunload = qcodo.handleBeforeUnload;


////////////////////////////////
// Qcodo Shortcut and Initialize
////////////////////////////////

var qc = qcodo;
window.qc = qc;