/////////////////////////////////
// Controls-related functionality
/////////////////////////////////

qcodo.extend(qcodo, {
	getControl: function(mixControl) {
		if (typeof(mixControl) == 'string')
			return document.getElementById(mixControl);

		return mixControl;
	},

	getWrapper: function(mixControl) {
		var objControl = qcodo.getControl(mixControl);
		if (objControl)
			return qcodo.getControl(objControl.id + '_ctl');

		return null;
	}
});

// Functions for objWrapper
qcodo.objWrapperExtender = {

	// Updating Style-related Things
	updateStyle: function(strStyleName, strNewValue) {
		var objControl = this.control;
		var recordControlMod = qcodo.recordControlModification;

		switch (strStyleName) {
			case "className":
				objControl.className = strNewValue;
				recordControlMod(objControl.id, "CssClass", strNewValue);
				break;

			case "parent":
				if (strNewValue) {
					var objNewParentControl = qcodo.getControl(strNewValue);
					objNewParentControl.appendChild(this);
					recordControlMod(objControl.id, "Parent", strNewValue);
				} else {
					var objParentControl = this.parentNode;
					objParentControl.removeChild(this);
					recordControlMod(objControl.id, "Parent", "");
				}
				break;

			case "display":
				if (strNewValue) {
					this.style.display = "inline";
					recordControlMod(objControl.id, "Display", "1");
				} else {
					this.style.display = "none";
					recordControlMod(objControl.id, "Display", "0");
				}
				break;

			case "enabled":
				if (strNewValue) {
					this.control.removeAttribute('disabled');
					recordControlMod(objControl.id, "Enabled", "1");
				} else {
					this.control.setAttribute('disabled', 'disabled');
					recordControlMod(objControl.id, "Enabled", "0");
				}
				break;

			case "width":
			case "height":
				objControl.style[strStyleName] = strNewValue;
				if (qcodo.javascriptStyleToQcodo[strStyleName])
					recordControlMod(objControl.id, qcodo.javascriptStyleToQcodo[strStyleName], strNewValue);
				if (this.handle)
					this.updateHandle();
				break;

			case "text":
				objControl.innerHTML = strNewValue;
				recordControlMod(objControl.id, "Text", strNewValue);
				break;

			default:
				objControl.style[strStyleName] = strNewValue;
				if (qcodo.javascriptStyleToQcodo[strStyleName]) {
					recordControlMod(objControl.id, qcodo.javascriptStyleToQcodo[strStyleName], strNewValue);
				}
				break;
		}
	},

	// Toggle Display / Enabled
	toggleDisplay: function(strShowOrHide, intDuration) {
		// Toggles the display/hiding of the entire control (including any design/wrapper HTML)
		// If ShowOrHide is blank, then we toggle
		// Otherwise, we'll execute a "show" or a "hide"

		intDuration = intDuration || 500; //default duration

		var show = function() {
			this.updateStyle("display", true);
			qcodo.animate(this, 'opacity: 1', { duration: intDuration });
		};

		var hide = function() {
			qcodo.animate(this, 'opacity: 0', { duration: intDuration, after: function() { this.updateStyle("display", false); } });
		};

		if (strShowOrHide) {
			if (strShowOrHide == "show")
				show();
			else
				hide();
		} else {
			if (this.style.display == "none")
				show();
			else
				hide();
		}
	},

	toggleEnabled: function(strEnableOrDisable) {
		if (strEnableOrDisable) {
			if (strEnableOrDisable == "enable")
				qcodo.updateStyle("enabled", true);
			else
				qcodo.updateStyle("enabled", false);
		} else
			qcodo.updateStyle("enabled", (this.control.disabled) ? true : false);
	},

	// Focus
	focus: function() {
		if ( this.control !== qcodo.safeActiveElement() && this.control.focus ) {
			this.control.focus();
		}
		return false;
	},

	// Blur
	blur: function () {
		if (this.control === qcodo.safeActiveElement() && this.control.blur) {
			this.control.blur();
		}
		return false;
	},

	// Select All (will only work for textboxes only)
	select: function() {
		if (this.control.select)
			this.control.select();
	},

	// Blink
	blink: function(toColor, duration) {
		duration = duration || 800; //default duration

		var control = this.control;
		control.originalBackgroundColor = qcodo.computedStyle(control).backgroundColor;

		qcodo.animate(control, 'background-color:' + toColor, {
			duration: duration / 2,
			after: function() {
				qcodo.animate(control, 'background-color:' + control.originalBackgroundColor, { duration: duration / 2 });
			}
		});
	}
};


/////////////////////////////
// Register Control - General
/////////////////////////////

qcodo.extend(qcodo, {
	controlModifications: {},

	javascriptStyleToQcodo: {
		backgroundColor: "BackColor",
		borderColor: "BorderColor",
		borderStyle: "BorderStyle",
		border: "BorderWidth",
		height: "Height",
		width: "Width",
		text: "Text"
	},

	recordControlMod: function(strControlId, strProperty, strNewValue) {
        var controlMod = qcodo.controlModifications;
		if (!controlMod[strControlId])
            controlMod[strControlId] = {};
        controlMod[strControlId][strProperty] = strNewValue;
	},
	
	registerControl: function(mixControl) {
		var objControl = qcodo.getControl(mixControl);
		if (!objControl)
			return;

		// Link the Wrapper and the Control together
		var objWrapper = qcodo.getWrapper(objControl);
		objControl.wrapper = objWrapper;
		objWrapper.control = objControl;

		// Create New Methods, etc.
		// Like: objWrapper.something = xyz;
        qcodo.extend(objWrapper, qcodo.objWrapperExtender);
	},

	registerControlArray: function(mixControlArray) {
        qcodo.each(mixControlArray, function(i, el) {
            qcodo.registerControl(el);
        });
	}
});

//////////////////
// Qcodo Shortcuts
//////////////////

qc.getC = qcodo.getControl;
qc.getW = qcodo.getWrapper;
qc.regC = qcodo.registerControl;
qc.regCA = qcodo.registerControlArray;
